import type {
    TileableModel,
    TileViewModel,
} from '@rocco/ui/tile-shared/view-models/tile';
import { Grid } from '@rocco/ui/layout/components/Grid';
import { Button } from '@rocco/components/button';

import { Link } from '@remix-run/react';
import { useRef, useState } from 'react';
import { CategoryPageTileWithAdminActions } from '../category-page/CategoryPageTileWithAdminActions';

import { mapDirectoryItemAdminToTile } from '~/modules-rocco/admin/data-mapper/directory-item-admin-to-tile';
import type { DirectoryItemAdmin } from '~/modules/directory/type';
import { useCategoryTilesTracking } from '@ui-rocco/hooks/use-category-tiles-tracking';

type CTAProps =
    | ({
          type: 'link';
          label: string;
          loading?: boolean;
      } & React.ComponentProps<typeof Link>)
    | { type: 'button'; onClick: () => void; label: string; loading?: boolean }
    | { type: 'showAll' };

/**
 * Props interface for the DetailsPageRelatedTileGrid component
 * @interface DetailsPageRelatedTileGridProps
 * @property {string} title - Title for the related items section
 * @property {TileViewModel<TileableModel>[]} tiles - Array of related tiles to display
 * @property {CTAProps} cta - Call to action configuration (either link or button)
 */
interface DetailsPageRelatedTileGridProps {
    title: string;
    tiles: TileViewModel<TileableModel>[];
    cta?: CTAProps;
    type: 'products' | 'projects' | 'articles' | 'professionals';
    displayCount?: {
        desktop: number;
        mobile: number;
    };
    columns?: {
        desktop: number;
        mobile: number;
    };
    appDisplayMode: 'mobile' | 'desktop';
    noSlice?: boolean;
    tilesAdminData?: DirectoryItemAdmin[];
}

/**
 * Component that renders a grid of related tiles with a title and CTA
 * Displays tiles in a responsive grid layout with a call-to-action button or link
 * @param {DetailsPageRelatedTileGridProps} props - Component props
 * @returns {JSX.Element} The rendered grid of related tiles
 */
export const DetailsPageRelatedTileGrid = (
    props: DetailsPageRelatedTileGridProps
) => {
    const {
        title,
        tiles,
        cta,
        displayCount = { desktop: 8, mobile: 4 },
        appDisplayMode,
        columns,
        noSlice = false,
        tilesAdminData,
    } = props;
    const gridRef = useRef<HTMLDivElement | null>(null);
    const [showAll, setShowAll] = useState(false);

    useCategoryTilesTracking(gridRef);

    if (tiles.length === 0) {
        return null;
    }

    const count = displayCount[appDisplayMode];

    const tilesToDisplay = showAll || noSlice ? tiles : tiles.slice(0, count);

    const tileDoms = tilesToDisplay.map((tile, index) => {
        const adminData = tilesAdminData?.find(
            (item) => item.id === tile.model.id
        );
        const adminStatusInfo = adminData
            ? mapDirectoryItemAdminToTile(adminData)
            : undefined;
        return (
            <div key={`related_tile_${tile.model.id}_${index}`}>
                <CategoryPageTileWithAdminActions
                    tile={{ ...tile, adminStatusInfo }}
                />
            </div>
        );
    });

    const gridColumns = getGridColumns(props.type, columns);

    return (
        <div>
            <div className="pb-8">
                <h2 className="text-xl tracking-[0.025rem] font-medium pt-5">
                    {title}
                </h2>
            </div>

            {tileDoms.length > 0 && (
                <>
                    <Grid
                        colClasses={gridColumns}
                        rowsGapClass="gap-x-2 gap-y-12 md:gap-x-6 md:gap-y-20"
                        animate={true}
                        ref={gridRef}
                    >
                        {tileDoms}
                    </Grid>

                    {cta && (
                        <div className="flex justify-center pt-12">
                            {cta.type === 'link' ? (
                                <Button
                                    color="secondary"
                                    weight="default"
                                    asChild
                                    isLoading={cta.loading}
                                >
                                    <Link {...cta}>{cta.label}</Link>
                                </Button>
                            ) : cta.type === 'button' ? (
                                <Button
                                    color="secondary"
                                    weight="default"
                                    onClick={cta.onClick}
                                    isLoading={cta.loading}
                                >
                                    {cta.label}
                                </Button>
                            ) : (
                                // Show all toggle
                                <Button
                                    color="secondary"
                                    weight="default"
                                    onClick={() => {
                                        setShowAll(!showAll);
                                    }}
                                >
                                    {showAll ? 'View Less' : 'View All'}
                                </Button>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

const getGridColumns = (
    type: DetailsPageRelatedTileGridProps['type'],
    columnsOverride?: DetailsPageRelatedTileGridProps['columns']
) => {
    if (columnsOverride) {
        return `grid-cols-${columnsOverride.mobile} md:grid-cols-${columnsOverride.desktop}`;
    }

    switch (type) {
        case 'products':
            return 'grid-cols-2 md:grid-cols-5';
        case 'projects':
            return 'grid-cols-1 md:grid-cols-4';
        case 'articles':
            return 'grid-cols-1 md:grid-cols-4';
        case 'professionals':
            return 'grid-cols-1 md:grid-cols-4';
    }
};
